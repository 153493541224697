import axios from 'axios';
import { url } from 'configs/url';

const url_fetch = url.site;

const version = "v1";

const generic_callback_error = (error) => { console.error(error)}
// API To Call
export const API = {
	HARDWARE : "hardware",
	GROUP_HARDWARE : "hardwareGroup",
	UPLOAD : "upload",

	CONTENT : "content",
	MODULE : "module",
	VERSION : "version",
	VIDEO : "video",
	SURVEY : "survey",
	LICENCE : "licence",
	TOKEN : "token",
	REPORT : "report",

    LANG : "lang",

	ROLE : "role",
    PERMISSION : "permission",
	USER : "user",
	ENTERPRISE : "enterprise",
	AUTH : "auth",
}

// GET /api/{dataCall}/{action}/
export function actionGetAPI(dataCall, action, callback, callbackError = generic_callback_error) {
    fetch(url_fetch+'/api/'+version+"/"+dataCall+'/'+action+'/', { 
        method: 'GET'
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    });
}

// GET /api/{dataCall}/:id
export function getOneAPI(dataCall, id, callback, callbackError = generic_callback_error, params = null) {
    const url_params = params !== null ? "?" + new URLSearchParams(params).toString() : "";
    axios.get(url_fetch+'/api/'+version+"/"+dataCall+'/'+id+url_params)
        .then(res => { 
            callback(res.data) 
        }).catch(error => {
            callbackError(error);
        });
}

export function getOneActionAPI(dataCall, action, id, callback, callbackError = generic_callback_error, params = null, source = null) {
    const url_params = params !== null ? "?" + new URLSearchParams(params).toString() : "";
    axios.get(url_fetch+'/api/'+version+"/"+dataCall+'/'+action+"/"+id+url_params, source !== null ? {cancelToken: source.token} : null)
        .then(res => { 
            callback(res.data) 
        }).catch(error => {
            callbackError(error);
        });
}

// GET /api/{dataCall}/
export function getAllAPI(dataCall, callback, callbackError = generic_callback_error, params = null, source = null) {
    const url_params = params !== null ? "?" + new URLSearchParams(params).toString() : "";
    axios.get(url_fetch+'/api/'+version+"/"+dataCall+'/'+url_params, source !== null ? {cancelToken: source.token} : null)
        .then(res => {
            callback(res.data); 
        }).catch(error => {
            callbackError(error);
        });
}

// POST /api/{dataCall}/
export function createAPI(dataCall, data, callback, callbackError = generic_callback_error) {
    fetch(url_fetch+'/api/'+version+"/"+dataCall+'/', { 
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            callbackError(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    });   
}

// PUT /api/{dataCall}/
export function updateAPI(dataCall, id, data, callback, callbackError = generic_callback_error) {
    // console.log(`${url_fetch}/api/${version}/${dataCall}/${id}`);
    axios.put(`${url_fetch}/api/${version}/${dataCall}/${id}`, data, {
        headers: { 'Content-Type': 'application/json' }
    }).then(response => {
        if (response.status === 200) {
            callback(response.data);
        } else {
            callbackError(response);
        }
    }).catch(error => {
        callbackError(error);
        // callbackError({error: error});
    });
}

// DELETE /api/{dataCall}/
export function deleteAPI(dataCall, id, callback, callbackError = generic_callback_error) {
    fetch(url_fetch+'/api/'+version+"/"+dataCall+'/'+id, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            console.error(res);
        }
    }).then(data => {
        callback(data);
    }).catch(error => {
        callbackError(error);
    });   
}


// POST /api/{dataCall}/{action}/
export function actionPostAPI(dataCall, action, data, callback, callbackError = generic_callback_error) {
    // console.log(`${url_fetch}/api/${version}/${dataCall}/${action}/`);
    axios.post(`${url_fetch}/api/${version}/${dataCall}/${action}/`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
    }).then(response => {
        if (response.status === 200) {
            callback(response.data);
        } else {
            callbackError(response);
        }
    }).catch(error => {
        callbackError(error.response.data.message);
    }); 
}

// PUT /api/{dataCall}/{action}/
export function actionPutAPI(dataCall, action, data, callback, callbackError = generic_callback_error) {
    fetch(url_fetch+'/api/'+version+"/"+dataCall+'/'+action+'/', { 
        method: 'PUT',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    });   
}

// Delete /api/{dataCall}/{action}/
export function actionDeleteAPI(dataCall, action, callback, callbackError = generic_callback_error) {
    axios.delete(`${url_fetch}/api/${version}/${dataCall}/${action}/`)
    .then(response => {
        callback(response);
    }).catch(error => {
        callbackError(error);
    });
}

// POST /api/v1/upload/
export function actionUploadImage(file, callbackProgress, callback, callbackError = generic_callback_error) {
    const formData = new FormData();
    formData.append('file', file);
    axios.post(url_fetch+'/api/'+version+"/"+API.UPLOAD+'/', formData, {
        onUploadProgress : callbackProgress
    }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    })
}

// POST /api/v1/upload/video
export function actionUploadVideo(file, callbackProgress, callback, callbackError = generic_callback_error) {
    const formData = new FormData();        
    formData.append('file', file);
    axios.post(url_fetch+'/api/'+version+"/"+API.UPLOAD+'/video', formData, {
        onUploadProgress : callbackProgress
    }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    })
}

// POST /api/v1/upload/srt
export function actionUploadSrt(file, callbackProgress, callback, callbackError = generic_callback_error) {
    const formData = new FormData();        
    formData.append('file', file);
    axios.post(url_fetch+'/api/'+version+"/"+API.UPLOAD+'/srt', formData, {
        onUploadProgress : callbackProgress
    }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    })
}

// POST /api/v1/upload/image360
export function actionUploadImage360(file, callbackProgress, callback, callbackError = generic_callback_error) {
    const formData = new FormData();        
    formData.append('file', file);
    axios.post(url_fetch+'/api/'+version+"/"+API.UPLOAD+'/image360', formData, {
        onUploadProgress : callbackProgress
    }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    })
}

// POST /api/v1/upload/sound
export function actionUploadSound(file, callbackProgress, callback, callbackError = generic_callback_error) {
    const formData = new FormData();        
    formData.append('file', file);
    axios.post(url_fetch+'/api/'+version+"/"+API.UPLOAD+'/sound', formData, {
        onUploadProgress : callbackProgress
    }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            console.error(res);
        }
    }).then(dataBack => {
        callback(dataBack);
    }).catch(error => {
        callbackError(error);
    })
}
