import { useContext } from 'react';
import { AuthContext } from '../store/auth.provider';
import { useNavigate } from 'react-router-dom';

export const usePermissions = () => {
    const { user } = useContext<any>(AuthContext);
    let history = useNavigate();

    const checkPermission = (permission) => {
        return user?.permissions?.includes(permission);
    }

    const isAdminOrManager = () => {
        return !user.role.is_client || user.role.name === "Client_Gerant";
    }

    // const checkRole = (role) => {
    //     return user?.role?.name === role;
    // }

    const isRevertoEmployee = () => {
        return user?.enterprise?.name === "REVERTO";
    }

    const checkRevertoOrHome = () => {
        if(!isRevertoEmployee()){
            history("/");
        }
    }

    return {
        user,
        isAdminOrManager,
        checkPermission,
        isRevertoEmployee,
        checkRevertoOrHome
    };
}
